import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import { IPInfoContext } from 'ip-info-react';
import _ from 'lodash';

// Import middlewares
import api from './../../other/middlewares/api';

// Import actions
import { setCurrentUser } from './../../other/actions/auth';
import { setGeneral } from './../../other/actions/general';

// Import helpers
import { slugByPageId } from './../../other/helpers/route-finder';

// Import components
import Loader from './../../components/loader/loader';
import Popup from './../../components/popup/popup';

// Import styles
import './header.scss';

interface props {};

const Header = (props: props) => {
	const dispatch = useDispatch();
	const location = useLocation();

	const userInfo = useContext(IPInfoContext);

	const auth = useSelector((state: {[key: string]: any}) => state.auth);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);
	const filter = useSelector((state: {[key: string]: any}) => state.filter);
	const general = useSelector((state: {[key: string]: any}) => state.general);
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	const initNotifications: {[key: string]: any} = {'title': '', 'text': []};
	// const initPopups = {
	// 	'showReferralsPopup': false
	// }

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [country, setCountry] = useState<string>('');
	// const [popups, setPopups] = useState<{[key: string]: any}>(initPopups);
	// const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false);
	const [notifications, setNotifications] = useState<{[key: string]: any}>(initNotifications);

	// const textareaRef = useRef<any>(null);

	useEffect(() => {
		if (userInfo.ip === undefined) { return; }
		if (
			userInfo.country_code !== 'LT' &&
			userInfo.country_code !== 'LV' &&
			userInfo.country_code !== 'EE' &&
			userInfo.country_code !== 'PL'
		) { return; }

		setCountry(userInfo.country_code);
	}, [userInfo]);

	const onAddToken = () => {
		if (!auth.isAuthenticated) { return; }

		setIsLoading(true);

		if (generalText.keys?.dailyCreditsAmount === 0) {
			return;
		}

		if (auth.creditsUpdatedAt && moment().diff(auth.creditsUpdatedAt, 'hours') < generalText.keys?.hoursToUpdateCredits) {
			setNotifications(generalText.messages?.creditsUpdateTimeError);

			return;
		}

		api.put('/add-daily-credit').then((res) => {
			dispatch(setCurrentUser({...auth, 'toggleUpdate': !auth.toggleUpdate}));
			setIsLoading(false);

		}).catch((err) => {
			setNotifications(err.response?.data?.messages);
			setIsLoading(false);
		});
	}

	// const onCopyLink = () => {
	// 	textareaRef.current.select();
	// 	document.execCommand('copy');
	// 	setIsLinkCopied(true);
	// }

	const isAuthenticated: boolean = auth?.isAuthenticated;

	let countActiveFilters = 0;

	_.map(filter, (val1: any, i1: any) => {
		if (val1 !== '' && val1 !== undefined) {
			countActiveFilters += 1;
		}
	});

	return (
		<>
			{/*<Popup
				title={generalText?.referralPopup?.title}
				submitBtn={auth.mainProfile?.slug ? (
					!isLinkCopied ? 
					generalText?.referralPopup?.button1?.text1 : 
					generalText?.referralPopup?.button1?.text2
				) : ''}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={popups.showReferralsPopup}
				onSubmit={() => onCopyLink()}
				onClose={() => setPopups(initPopups)}
			>
				<div className="SECTION no-bottom-padding">
					<div className="grid">
						<div className="row">
							<div className="col-12">
								{
									auth.mainProfile?.slug && 
									generalText?.referralPopup?.text1 &&
									<div dangerouslySetInnerHTML={{ __html: generalText?.referralPopup?.text1 }}></div>
								}

								{
									!auth.mainProfile?.slug && 
									generalText?.referralPopup?.text2 &&
									<div className="SETTINGS__coupon">
										<div dangerouslySetInnerHTML={{ __html: generalText?.referralPopup?.text2 }}></div>
									</div>
								}

								{
									auth.mainProfile?.slug &&
									<div className="SETTINGS__coupon">
										<div className="FIELD">
											{
												generalText?.referralPopup?.input1?.label &&
												<label>{ generalText?.referralPopup?.input1?.label }</label>
											}
											
											<input
												ref={textareaRef}
												type="text"
												onChange={() => {}}
												value={auth?.invitationCodeShareable}
											/>
										</div>
									</div>
								}
							</div>
						</div>
					</div>
				</div>
			</Popup>*/}

			<Popup
				title={notifications?.title || 'Something went wrong'}
				closeBtn={generalText?.generalPopup?.button1?.text}
				text={notifications?.text || ['Unhandled error - #24634634522']}
				active={!_.isEmpty(notifications?.text)}
				onClose={() => setNotifications(initNotifications)}
			/>

			<div className="SECTION HEADER">
				<div className="grid no-top-margin">
					<div className="SECTION__inner bg-diff1">
						<div className="grid">
							<div className="row">
								<div className="col-12">
									<div className="HEADER__inner">
										<Loader active={isLoading} fixed={false} />

										<div className="HEADER__logo">
											<NavLink 
												to={slugByPageId(generalText?.header?.logo?.link, lang, routes)}
												className="HEADER__logo-link"
											>
												{
													generalText?.footer?.logo?.image &&
													(
														(!auth.isAuthenticated && !generalText?.isDarkThemeEnabled) || 
														(auth.isAuthenticated && !auth.isDarkThemeEnabled)
													) &&
													<img src={process.env.PUBLIC_URL + '/assets/' + process.env?.REACT_APP_NAME?.toLowerCase() + '/el/' + generalText?.header?.logo?.image} className="logo" alt={generalText?.header?.logo?.alt} />
												}

												{
													generalText?.footer?.logo?.imageDiff1 &&
													(
														(auth.isAuthenticated && auth.isDarkThemeEnabled) || 
														(!auth.isAuthenticated && generalText?.isDarkThemeEnabled)
													) &&
													<img src={process.env.PUBLIC_URL + '/assets/' + process.env?.REACT_APP_NAME?.toLowerCase() + '/el/' + generalText?.header?.logo?.imageDiff1} className="logo" alt={generalText?.header?.logo?.alt} />
												}

												{
													generalText?.header?.logo?.text1 &&
													<span className="text" dangerouslySetInnerHTML={{ __html: generalText?.header?.logo?.text1 }}></span>
												}

												{
													generalText?.header?.logo?.badge &&
													<span className="badge">{ generalText?.header?.logo?.badge }</span>
												}

												{
													!generalText?.header?.logo?.badge &&
													process.env?.REACT_APP_NAME === 'TekBruv' &&
													<span className="badge">{ country }</span>
												}
											</NavLink>
										</div>

										<div className="HEADER__content">
											{/*{
												isAuthenticated && 
												generalText?.header?.button1?.text &&
												<div className="HEADER__content-item clickable">
													<span onClick={() => setPopups({...initPopups, 'showReferralsPopup': true})} className="BUTTON diff1 no-top-margin">{ generalText?.header?.button1?.text }</span>
												</div>
											}*/}

											{
												(
													location.pathname === slugByPageId('about1', lang, routes) ||
													location.pathname === slugByPageId('faq', lang, routes) ||
													location.pathname === slugByPageId('terms', lang, routes) ||
													location.pathname === slugByPageId('privacy', lang, routes) ||
													location.pathname === slugByPageId('cookies', lang, routes)
												) &&
												<>
													{
														(
															!isAuthenticated && 
															generalText?.header?.button2?.text
														) &&
														<div className="HEADER__content-item clickable">
															<Link to={slugByPageId(generalText?.header?.button2?.link, lang, routes)} className="BUTTON size2 diff2">{ generalText?.header?.button2?.text }</Link>
														</div>
													}

													{
														(
															!isAuthenticated && 
															generalText?.header?.button3?.text
														) &&
														<div className="HEADER__content-item clickable">
															<Link to={slugByPageId(generalText?.header?.button3?.link, lang, routes)} className="BUTTON size2">{ generalText?.header?.button3?.text }</Link>
														</div>
													}
												</>
											}

											{
												(
													!isAuthenticated && 
													generalText?.header?.button4?.text &&
													location.pathname !== slugByPageId('about1', lang, routes) &&
													location.pathname !== slugByPageId('faq', lang, routes) &&
													location.pathname !== slugByPageId('terms', lang, routes) &&
													location.pathname !== slugByPageId('privacy', lang, routes) &&
													location.pathname !== slugByPageId('cookies', lang, routes)
												) &&
												<div className="HEADER__content-item clickable">
													<Link to={slugByPageId(generalText?.header?.button4?.link, lang, routes)} className="BUTTON size2 diff2">{ generalText?.header?.button4?.text }</Link>
												</div>
											}

											{
												isAuthenticated &&
												(location.pathname !== slugByPageId('main', lang, routes)) &&
												<div className="HEADER__content-item">
													<div className="HEADER__credits">
														<span className="HEADER__credits-total">
															<span className={'HEADER__credits-total-icon ' + (generalText?.header?.credits?.icon)}></span>
															<span className="HEADER__credits-total-text">
																{ auth?.credits || 0 }
															</span>
														</span>

														{
															(
																!auth?.creditsUpdatedAt || 
																moment().diff(auth?.creditsUpdatedAt, 'hours') > generalText.keys?.hoursToUpdateCredits
															) &&
															generalText.keys?.dailyCreditsAmount !== 0 &&
															<span onClick={() => onAddToken()} className="HEADER__credits-new">+ { generalText.keys?.dailyCreditsAmount }</span>
														}
													</div>
												</div>
											}

											{
												isAuthenticated &&
												(location.pathname === slugByPageId('main', lang, routes)) &&
												<div className="HEADER__content-item clickable">
													<span onClick={() => dispatch(setGeneral({...general, 'showNewPostPopup': !general.showNewPostPopup}))} className="link">
														<span className={generalText?.header?.button5?.icon}></span>
													</span>
												</div>
											}

											{
												isAuthenticated &&
												(location.pathname === slugByPageId('main', lang, routes)) &&
												<div className="HEADER__content-item clickable">
													<span onClick={() => dispatch(setGeneral({...general, 'showFilterPopup': !general.showFilterPopup}))} className="link">
														{
															countActiveFilters !== 0 &&
															<span className="badge">{ countActiveFilters }</span>
														}
														<span className={generalText?.header?.button6?.icon}></span>
													</span>
												</div>
											}

											{
												isAuthenticated && 
												generalText?.header?.button7?.icon &&
												(location.pathname !== slugByPageId('main', lang, routes)) &&
												<div className="HEADER__content-item clickable">
													<NavLink 
														to={slugByPageId(generalText?.header?.button7?.link, lang, routes)}
														className={(navData: {[key: string]: any}) => navData.isActive ? 'link active' : 'link'}
													>
														<span className={generalText?.header?.button7?.icon}></span>
													</NavLink>
												</div>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Header;